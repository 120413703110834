import { Card, CardContent, Chip, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import { getLogs } from "../store/slices/logSlice";
import "../styles/operation.css";
import {getTransactions} from "../store/slices/transactionSlice";
import {unwrapResult} from "@reduxjs/toolkit"; // Tell webpack that Button.js uses these styles

function Logs() {

  const [perPage, setPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [page, setPage] = useState(1);

  function returnStatus(status) {
    switch (status) {
      case "withdraw":
        return <Chip label="Вывод" color="error" />;
      case "deposit":
        return <Chip label="Пополнение" color="success" />;
      case "reserve":
        return <Chip label="Заморозка" color="info" />;
      case "cancel_reserve":
        return <Chip label="Отмена заморозки" color="warning" />;
      default:
        return <Chip label="Неизвестно" />;
    }
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 75,
    },
    {
      field: "user",
      headerName: "Пользователь",
      width: 150,
      valueGetter: (params) => {
        return params.value?.login;
      },
    },

    {
      field: "operation",
      headerName: "Тип операции",
      width: 150,
      renderCell: (params) => returnStatus(params.value),
    },
    {
      field: "description",
      headerName: "Описание",
      width: 400,
    },

    {
      field: "amount",
      headerName: "Сумма",
      width: 120,
      valueGetter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "balance_before",
      headerName: "Сумма до",
      width: 120,
      valueGetter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "balance_after",
      headerName: "Сумма после",
      width: 120,
      valueGetter: (params) => {
        return params.value.toFixed(2);
      },
    },
    {
      field: "created_at",
      headerName: "Дата создания",
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD.MM.YYYY HH:mm");
      },
    },
  ];

  const [tableHeight, setTableHeight] = React.useState(
    window.innerHeight - 200
  );
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.log.logs);

  const resize = async () => {
    console.log(window.innerHeight);
    setTableHeight(window.innerHeight - 200);
  };

  React.useEffect(() => {
    dispatch(
        getLogs({ page: 1, perPage: perPage, searchTerm: searchTerm })
    )
        .then(unwrapResult)
        .then((result) => {
          console.log(result); // => 233
          setRows(result.data.data);
        })
        .catch((error) => {
          console.error(error); // if there is an error
        });

    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
      // clearInterval(intervalId);
    };
  }, []);



  function handlePageChange(page) {
    // const {page} = params

    setPage(page + 1);
    // console.log('test', params)
    dispatch(
        getLogs({
          page: page + 1,
          perPage: perPage,
          searchTerm: searchTerm,
        })
    )
        .then(unwrapResult)
        .then((result) => {
          console.log(result); // => 233
          setRows(result.data.data);
        })
        .catch((error) => {
          console.error(error); // if there is an error
        });
  }

  const handlePageSizeChange = (newPerPage) => {
    setPerPage(newPerPage);
    console.log(newPerPage);
    console.log(page);

    dispatch(
        getLogs({
          page: page,
          perPage: perPage,
          newPerPage: newPerPage,
          searchTerm: searchTerm,
        })
    )
        .then(unwrapResult)
        .then((result) => {
          console.log(result); // => 233
          setRows(result.data.data);
        })
        .catch((error) => {
          console.error(error); // if there is an error
        });

  };

  const handleFilterModelChange = (model) => {
    console.log(model.quickFilterValues[0]);
    setSearchTerm(model.quickFilterValues[0]);
    dispatch(
        getLogs({
          page: page,
          perPage: perPage,
          searchTerm: model.quickFilterValues[0],
        })
    )
        .then(unwrapResult)
        .then((result) => {
          console.log(result); // => 233
          setRows(result.data.data);
        })
        .catch((error) => {
          console.error(error); // if there is an error
        });
  };

  return (
    <div>
      <Card sx={{ minWidth: 275 }}>
        <CardContent className="text-start p-3">
          <Typography color="text.primary">Операции</Typography>

          <div className="row">
            <div className="col-12 mt-3">
              <div style={{ height: tableHeight, width: "100%" }}>
                <DataGridPremium
                  getRowClassName={(params) => `color-${params.row.status}`}
                  rows={rows}
                  paginationMode
                  pageSize={perPage}
                  disableColumnFilter={true}
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                  pagination
                  onPageChange={handlePageChange}
                  onFilterModelChange={handleFilterModelChange}
                  rowCount={logs.meta?.total}
                  columns={columns}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    toolbar: {
                      filename: "Операции",
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },

                    },
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [
                        {
                          field: "created_at",
                          sort: "desc",
                        },
                      ],
                    },
                  }}
                  localeText={localeGrid}
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default Logs;
