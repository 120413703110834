import {Checkbox, FormControlLabel, TextField, Typography,} from "@mui/material";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {editSettings} from "../store/slices/settingsSlice";

function TechnicalSetting() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  // const [technicalWork, setTechnicalWork] = React.useState(false);

  const { register, handleSubmit, control, watch, reset, setValue } = useForm({
    defaultValues: {
      id: null,
      name: "technical_work",
      is_activate: false,
      value: "",
    },
  });

    const watchForm = watch(); // you can supply default value as second argument

    React.useEffect(() => {
        dispatch(editSettings(watchForm));
    }, [watchForm]);

    React.useEffect(() => {
        let technical_work = settings.find(
          (item) => item.name === "technical_work"
        );
        if (technical_work) {
          setValue("value", technical_work.value);
          if (technical_work.is_activate == 1) {
            setValue("is_activate", true);
          } else {
            setValue("is_activate", false);
          }
          setValue("id", technical_work.id);
        }
  }, []);

    // const onSubmit = (data) => {
    //   dispatch(editTechnicalWork(data))
    //     .then(unwrapResult)
    //     .then((result) => {
    //       console.log("result", result); // => 233
    //       dispatch(
    //         setSnackBar({
    //           open: true,
    //           text: "Успешно",
    //         })
    //       );
    //       // reset()
    //     })
    //     .catch((error) => {
    //       dispatch(
    //         setSnackBar({
    //           open: true,
    //           text: "Ошибка",
    //         })
    //       );
    //       // reset()
    //       console.error("error", error); // if there is an error
    //     });
    // };

  return (


        <div className="row mt-1">
          <Typography variant="subtitle2" color="text.primary">
            Состояние
          </Typography>
          <div className="mt-1">
            {/* <Stack direction="row" spacing={1} alignItems="center">
              <Typography>Отключено</Typography>
              <Switch checked={technicalWork} onChange={handleChange} />
              <Typography>Включено</Typography>
            </Stack> */}

            <FormControlLabel
              control={
                <Controller
                  name="is_activate"
                  control={control}
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label="Включить технические работы"
            />
          </div>
          <div className="mt-3">
            <Controller
              name="value"
              control={control}
              render={({ field }) => (
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  {...field}
                  type="text"
                  rows={4}
                  multiline={true}
                  label={`Сообщение`}
                />
              )}
            />
          </div>
        </div>

      // <div className="d-flex  justify-content-center">
      //   <Button
      //     variant="outlined"
      //     className="mt-1 mb-3 mx-1"
      //     onClick={handleSubmit(onSubmit)}
      //   >
      //     Сохранить
      //   </Button>
      // </div>
      // </Card>
  );
}

export default TechnicalSetting;
