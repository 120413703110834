import CheckIcon from "@mui/icons-material/Check";
import {
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { DataGridPremium, GridActionsCellItem } from "@mui/x-data-grid-premium";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import localeGrid from "../components/localeGrid";
import {
  compTransaction,
  getTransactions,
} from "../store/slices/transactionSlice";
import "../styles/transaction.css";

function Transactions() {
  const [perPage, setPerPage] = useState(10);
  const [rows, setRows] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 75,
      filterable: false,
    },

    {
      field: "user",
      headerName: "User",
      width: 75,
      valueGetter: (params) => {
        return params.value.login;
      },
    },
    {
      field: "user_id",
      headerName: "User ID",
      width: 75,
    },
    {
      field: "amount",
      headerName: "Сумма",
      width: 100,
    },
    {
      field: "amount_usdt",
      headerName: "Сумма USDT",
      width: 100,
      valueFormatter: (params) => {
        return Number(params.value).toFixed(2);
      },
    },

    {
      field: "status",
      headerName: "Статус",
      width: 150,
      renderCell: (params) => returnStatus(params.value, params.row.is_deleted),
    },
    {
      field: "is_api",
      headerName: "API/UI",
      width: 100,
      renderCell: (params) =>
        params.value == 1 ? (
          <Chip label="API" size="small" color="primary" />
        ) : (
          <Chip label="UI" size="small" color="primary" />
        ),
    },

    {
      field: "comment",
      headerName: "Комментарий",
      width: 120,
    },
    {
      field: "paysources_filter",
      headerName: "Тип платежа",
      width: 100,
    },
    {
      field: "card_number",
      headerName: "Номер карты",
      width: 120,
    },
    {
      field: "created_at",
      headerName: "Дата создания",
      width: 150,
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD.MM.YYYY HH:mm");
      },
    },

    {
      field: "uid",
      headerName: "UID",
      width: 300,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Действия",
      width: 100,
      getActions: (params) => returnArrayActions(params),
    },
  ];

  function returnArrayActions(params) {
    if (
      params.row.status == "Canceled" ||
      params.row.status == "CanceledNotRecalculation"
    ) {
      return [
        <GridActionsCellItem
          icon={<CheckIcon color="success" fontSize="large" />}
          label="Complete"
          onClick={completeTransaction(params.row)}
        />,
      ];
    } else {
      return [];
    }
  }

  function returnLink(params) {
    if (params.row.merchant.type == "btcn") {
      return <div>{params.row.payment_url}</div>;
    }
    if (params.row.merchant.type == "mpc") {
      return <a href={params.row.payment_url}>{params.row.payment_url}</a>;
    }
  }

  function returnStatus(status, is_deleted) {
    // console.log('status', status)
    let chip;
    switch (status) {
      case "new":
        chip = <Chip label="Новая" size="small" />;
        break;
      case "payed":
        chip = <Chip label="Оплачено" size="small" color="success" />;
        break;
      case "paymentTimeoutNotPayed":
        chip = (
          <Chip
            label="Истекло время оплаты"
            size="small"
            sx={{
              background: "#f44336",
            }}
          />
        );
        break;
      case "On checking":
        chip = (
          <Chip
            label="На проверке"
            size="small"
            sx={{
              background: "#ffeb3b",
            }}
          />
        );
        break;
      case "Canceled":
        chip = (
          <Chip
            label="Закрыта"
            size="small"
            sx={{
              background: "#f44336",
            }}
          />
        );
        break;
      case "CanceledNotRecalculation":
        chip = (
          <Chip
            label="Закрыта"
            size="small"
            sx={{
              background: "#f44336",
              color: "#fff",
            }}
          />
        );
        break;
      case "Finished":
        chip = <Chip label="Оплачено" color="success" size="small" />;
        break;
      default:
        chip = <Chip label="Неизвестно" size="small" />;
        break;
    }

    let chipDeleted;
    if (is_deleted) {
      chipDeleted = (
        <Chip
          label="Удалена клиентом"
          size="small"
          className="mx-1"
          sx={{
            background: "#f44336",
          }}
        />
      );
    }

    return (
      <div className="d-flex">
        {chip}
        {chipDeleted}
      </div>
    );
  }

  const [searchTerm, setSearchTerm] = useState(null);

  const [tableHeight, setTableHeight] = React.useState(window.innerHeight);
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transaction.transactions);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [deletedTransactions, setDeletedTransactions] = React.useState([]);
  const [openShowDialog, setOpenShowDialog] = React.useState(false);
  const [clienInfo, setClienInfo] = React.useState({});
  const [page, setPage] = useState(1);
  const [openCompleteDialog, setOpenCompleteDialog] = React.useState(false);

  const resize = async () => {
    console.log(window.innerHeight);
    setTableHeight(window.innerHeight - 200);
  };

  React.useEffect(() => {
    dispatch(
      getTransactions({ page: 1, perPage: perPage, searchTerm: searchTerm })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });

    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
      // clearInterval(intervalId);
    };
  }, []);

  const {
    control: controlComplete,
    handleSubmit: handleSubmitComplete,
    setValue: setValueComplete,
  } = useForm({
    defaultValues: {
      id: null,
      fiat_amount: "",
    },
  });

  const completeTransaction = React.useCallback(
    (row) => () => {
      console.log(row);
      setValueComplete("id", row.id);
      setValueComplete("fiat_amount", row.amount);

      // let array = []
      // array.push(Number(id))
      setOpenCompleteDialog(true);
      // setDeletedTransactions(array)
    },
    []
  );

  const deleteTransaction = React.useCallback(
    (id) => () => {
      console.log(id);
      let array = [];
      array.push(Number(id));
      setOpenDeleteDialog(true);
      setDeletedTransactions(array);
    },
    []
  );
  /**
   * Метод
   */
  const editTransaction = React.useCallback(
    (row) => () => {
      console.log(row);
      setValue("id", row.id);
      setValue("amount", row.amount);
      setValue("status", row.status);
      setValue("comment", row.comment);
      setValue("paysources_filter", row.paysources_filter);
      setValue("card_number", row.card_number);
      setValue("email", row.email);

      for (const clientInfoJsonKey in row.client_info_json) {
        console.log(clientInfoJsonKey);
        setValueClientInfo(
          clientInfoJsonKey,
          row.client_info_json[clientInfoJsonKey]
        );
      }
      setClienInfo(row.client_info_json);

      setOpenEditDialog(true);

      // setValue()

      // let array = []
      // array.push(Number(id))
      // setDeletedUsers(array)
      // setOpenDeleteDialog(true)
      // dispatch(delUsers(array))
    },
    []
  );

  const showClientInfo = React.useCallback(
    (row) => () => {
      console.log(row);

      for (const clientInfoJsonKey in row.client_info_json) {
        console.log(clientInfoJsonKey);
        setValueClientInfo(
          clientInfoJsonKey,
          row.client_info_json[clientInfoJsonKey]
        );
      }
      setClienInfo(row.client_info_json);
      setOpenShowDialog(true);

      // let array = []
      // array.push(Number(id))
      // setDeletedUsers(array)
      // setOpenDeleteDialog(true)
      // dispatch(delUsers(array))
    },
    []
  );

  const deleteSelected = () => {
    const array = selectionModel.map((id) => Number(id));
    setDeletedTransactions(array);
    // dispatch(deleteTokens(array))
    setOpenDeleteDialog(true);
  };

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      id: null,
      amount: "",
      status: "",
      comment: "",
      paysources_filter: "",
      card_number: "",
      email: "",
    },
  });

  const {
    register: registerClientInfo,
    control: controlClientInfo,
    setValue: setValueClientInfo,
    getValues: getValuesClientInfo,
  } = useForm({});

  const {
    control: controlShow,
    reset: resetShow,
    setValue: setValueShow,
  } = useForm({
    defaultValues: {
      public_key: "",
      private_key: "",
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    // dispatch(login(data))
    //     .then(unwrapResult)
    //     .then((result) => {
    //         console.log(result); // => 233
    //         // navigate.push('/dashboard')
    //         navigate("/dashboard", {replace: true});
    //     })
    //     .catch((error) => {
    //         console.error(error); // if there is an error
    //     });
  };

  const closeDeleteDialog = (event, reason) => {
    setSelectionModel([]);
    setOpenDeleteDialog(false);
  };

  function dialogDeleteTransactions() {
    // dispatch(deleteTransactions(deletedTransactions))
    setOpenDeleteDialog(false);
  }

  const closeEditDialog = (event, reason) => {
    setOpenEditDialog(false);
  };

  const onCompleteTransaction = (data) => {
    console.log(data);
    dispatch(compTransaction(data)).finally(() => {
      setOpenCompleteDialog(false);
      // console.log('transactions.data', transactions.data)
      // setRows(transactions.data)

      dispatch(
        getTransactions({
          page: page,
          perPage: perPage,
          searchTerm: searchTerm,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          console.log(result); // => 233
          setRows(result.data.data);
        })
        .catch((error) => {
          console.error(error); // if there is an error
        });
    });
  };

  function handlePageChange(page) {
    // const {page} = params

    setPage(page + 1);
    // console.log('test', params)
    dispatch(
      getTransactions({
        page: page + 1,
        perPage: perPage,
        searchTerm: searchTerm,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });
  }

  const handlePageSizeChange = (newPerPage) => {
    setPerPage(newPerPage);
    console.log(newPerPage);
    console.log(page);

    dispatch(
      getTransactions({
        page: page,
        perPage: perPage,
        newPerPage: newPerPage,
        searchTerm: searchTerm,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });
    // paginateById(page, pageSize, params.pageSize)
    //     .then((results) => {
    //         console.log(results)
    //         // Handle updated results
    //     })
    //     .catch((error) => {
    //         console.error(error)
    //     })
  };

  const handleFilterModelChange = (model) => {
    console.log(model.quickFilterValues[0]);
    setSearchTerm(model.quickFilterValues[0]);
    dispatch(
      getTransactions({
        page: page,
        perPage: perPage,
        searchTerm: model.quickFilterValues[0],
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log(result); // => 233
        setRows(result.data.data);
      })
      .catch((error) => {
        console.error(error); // if there is an error
      });
  };

  return (
    <div>
      <Card sx={{ minWidth: 275 }}>
        <CardContent className="text-start p-3">
          <Typography color="text.primary">Транзакции</Typography>

          <div className="row">
            <div className="col-12 justify-content-end text-end">
              {/*<Button variant="contained" className="" onClick={generateToken}>*/}
              {/*    Сгенерировать ключ*/}
              {/*</Button>*/}

              {/*<Button variant="contained" className="ms-3" onClick={deleteSelected}*/}
              {/*        disabled={selectionModel.length == 0}>*/}
              {/*    Удалить выбранные*/}
              {/*</Button>*/}
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <div style={{ height: tableHeight, width: "100%" }}>
                <DataGridPremium
                  rows={rows}
                  columns={columns}
                  paginationMode="server"
                  pageSize={perPage}
                  disableColumnFilter={true}
                  onPageSizeChange={handlePageSizeChange}
                  rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                  pagination
                  onPageChange={handlePageChange}
                  onFilterModelChange={handleFilterModelChange}
                  rowCount={transactions.meta?.total}
                  getRowClassName={(params) => `color-${params.row.status}`}
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    toolbar: {
                      filename: "Транзакции",
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                  rowHeight={80}
                  checkboxSelection
                  disableSelectionOnClick
                  initialState={{
                    sorting: {
                      sortModel: [
                        {
                          field: "id",
                          sort: "desc",
                        },
                      ],
                    },
                    pinnedColumns: { right: ["actions"] },
                  }}
                  onSelectionModelChange={setSelectionModel}
                  selectionModel={selectionModel} //
                  localeText={localeGrid}
                />
              </div>
            </div>
          </div>

          {/*<Dialog*/}
          {/*    open={openDeleteDialog}*/}
          {/*    onClose={closeDeleteDialog}*/}
          {/*    aria-labelledby="alert-dialog-title"*/}
          {/*    aria-describedby="alert-dialog-description"*/}
          {/*>*/}
          {/*    <DialogTitle id="alert-dialog-title">*/}
          {/*        Подтвердить действие*/}
          {/*    </DialogTitle>*/}
          {/*    <DialogContent>*/}
          {/*        <DialogContentText id="alert-dialog-description">*/}
          {/*            Удалить выбранные транзакции?*/}
          {/*        </DialogContentText>*/}
          {/*    </DialogContent>*/}
          {/*    <DialogActions>*/}
          {/*        <Button onClick={closeDeleteDialog}>Отмена</Button>*/}
          {/*        <Button onClick={() => dialogDeleteTransactions()} autoFocus>*/}
          {/*            Да*/}
          {/*        </Button>*/}
          {/*    </DialogActions>*/}
          {/*</Dialog>*/}

          <Dialog
            open={openCompleteDialog}
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenCompleteDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Завершить заявку</DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-12 mt-3">
                  <form>
                    <div className="mt-1">
                      <Controller
                        name="fiat_amount"
                        control={controlComplete}
                        render={({ field }) => (
                          <TextField
                            className="w-100"
                            label="Сумма"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenCompleteDialog(false)}>
                Отмена
              </Button>
              <Button onClick={handleSubmitComplete(onCompleteTransaction)}>
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openShowDialog}
            maxWidth="md"
            fullWidth={true}
            onClose={() => setOpenShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Инорфация clientInfo
            </DialogTitle>
            <DialogContent>
              <div className="row">
                <div className="col-12">
                  <form>
                    {Object.keys(clienInfo).map((keyName, i) => (
                      <div className="mt-3" key={keyName}>
                        <Controller
                          name={keyName}
                          control={controlClientInfo}
                          render={({ field }) => (
                            <TextField
                              className="w-100"
                              label={keyName}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    ))}
                  </form>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenShowDialog(false)}>Закрыть</Button>
            </DialogActions>
          </Dialog>

          {/*<form className="mt-3">*/}
          {/*    <div>*/}
          {/*        <Controller*/}
          {/*            name="address"*/}
          {/*            control={control}*/}
          {/*            render={({field}) => <TextField sx={{width: '100%'}} {...field}*/}
          {/*                                            label="Адрес для вывода"/>}*/}
          {/*        />*/}
          {/*    </div>*/}

          {/*</form>*/}

          {/*<Button variant="outlined" className="mt-3"*/}
          {/*        onClick={handleSubmit(onSubmit)}>Сохранить</Button>*/}
        </CardContent>
      </Card>
    </div>
  );
}

export default Transactions;
