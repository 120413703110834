import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  transactions: {
    data: [],
    meta: {
      total: 0,
    },
  },
  statistics: [],

  loading: "IDLE",
  error: null,
};

export const getTransactions = createAsyncThunk(
  "token/getTokens",
  async (params) => {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api-admin/get-transactions?page=${params.page}&perPage=${params.perPage}&newPerPage=${params.newPerPage}&searchTerm=${params.searchTerm}`
    );
    console.log(response.data);
    return response.data;
  }
);

export const getStatistics = createAsyncThunk(
  "transaction/getStatistics",
  async (data) => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-admin/get-statistics`,
      data
    );
    console.log(response.data);
    return response.data;
  }
);

export const compTransaction = createAsyncThunk(
  "token/completeTransaction",
  async (data) => {
    let response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api-admin/complete-transactions`,
      data
    );
    console.log(response.data);
    return response.data;
  }
);

export const transactionSlice = createSlice({
  name: "token",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.loading = "loading";
        state.error = null;
      })

      .addCase(getTransactions.fulfilled, (state, action) => {
        state.loading = "idle";
        state.transactions = action.payload.data;
        state.error = null;
      })

      .addCase(getStatistics.fulfilled, (state, action) => {
        state.loading = "idle";
        state.statistics = action.payload.data;
        state.error = null;
      })

      .addCase(compTransaction.fulfilled, (state, action) => {
        state.loading = "idle";
        console.log(action.payload.data);
      })

      .addCase(getTransactions.rejected, (state, action) => {
        state.transactions = [];
        state.loading = "failed";
        state.error = action.payload.code;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = transactionSlice.actions;

export default transactionSlice.reducer;
