import React from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider, Fade, FormControl,
    FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select,
    TextField,
    Typography
} from "@mui/material";
import '../styles/operation.css'; // Tell webpack that Button.js uses these styles
import {useForm, Controller} from "react-hook-form";
import {login} from "../store/slices/authSlice";
import {unwrapResult} from "@reduxjs/toolkit";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import CustomToolbar from "../components/CustomToolbar";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from "../layouts/Header";
import {createOperation, deleteOperations, editOperation, getOperations} from "../store/slices/operationSlice";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {DataGridPremium, GridActionsCellItem} from "@mui/x-data-grid-premium";
import localeGrid from "../components/localeGrid";
import {getLogs, getLogsRefferals} from "../store/slices/logSlice";

function LogsRefferals() {

    function returnStatus(status) {
        switch (status) {
            case 'withdraw':
                return <Chip label="Уменьшение" color="error"/>;
            case 'deposit':
                return <Chip label="Увеличение" color="success"/>;
            default:
                return <Chip label="Неизвестно"/>;
        }
    }

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75
        },
        {
            field: 'user',
            headerName: 'Пользователь',
            width: 150,
            valueGetter: (params) => {
                return params.row.user.login
            }
        },

        {
            field: 'refferal',
            headerName: 'Реферал',
            width: 150,
            valueGetter: (params) => {
                return params.row.refferal.login
            }
        },
        {
            field: 'transaction',
            headerName: 'Сумма',
            width: 150,
            valueGetter: (params) => {
                return params.row.transaction?.amount_usdt
            }
        },
        {
            field: 'amount',
            headerName: 'Вознаграждение',
            width: 150,
            // valueGetter: (params) => {
            //     return params.row.value
            // }
        },

        {
            field: 'transaction2',
            headerName: 'Transaction ID',
            width: 150,
            valueGetter: (params) => {
                return params.row.transaction?.id
            }
        },
        //
        // {
        //     field: 'operation',
        //     headerName: 'Тип операции',
        //     width: 150,
        //     renderCell: (params) => returnStatus(params.value)
        //
        // },
        // {
        //     field: 'description',
        //     headerName: 'Описание',
        //     width: 400,
        // },
        //
        // {
        //     field: 'amount',
        //     headerName: 'Сумма',
        //     width: 120,
        //     valueGetter: (params) => {
        //         return params.value.toFixed(2)
        //     }
        // },
        // {
        //     field: 'balance_before',
        //     headerName: 'Сумма до',
        //     width: 120,
        //     valueGetter: (params) => {
        //         return params.value.toFixed(2)
        //     }
        // },
        // {
        //     field: 'balance_after',
        //     headerName: 'Сумма после',
        //     width: 120,
        //     valueGetter: (params) => {
        //         return params.value.toFixed(2)
        //     }
        // },
        {
            field: 'created_at',
            headerName: "Дата создания",
            width: 150,
            valueFormatter: (params) => {
                return dayjs(params.value).format('DD.MM.YYYY HH:mm')
            }
        }
    ];


    const [tableHeight, setTableHeight] = React.useState(window.innerHeight - 200);
    const dispatch = useDispatch();
    const logs = useSelector((state) => state.log.logs_refferals)

    const resize = async () => {
        console.log(window.innerHeight)
        setTableHeight(window.innerHeight - 200)
    }

    React.useEffect(() => {
        dispatch(getLogsRefferals())
        resize()
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [])

    return (
        <div>
            <Card sx={{minWidth: 275}}>
                <CardContent className="text-start p-3">
                    <Typography color="text.primary">
                        Операции
                    </Typography>


                    <div className="row">
                        <div className="col-12 mt-3">
                            <div style={{height: tableHeight, width: '100%'}}>
                                <DataGridPremium
                                    // rowHeight={80}
                                    getRowClassName={(params) => `color-${params.row.status}`}
                                    rows={logs}
                                    columns={columns}
                                    components={{Toolbar: CustomToolbar}}
                                    componentsProps={{
                                        toolbar: {
                                                filename: 'Операции',
                                                showQuickFilter: true,
                                            }
                                    }}
                                    initialState={{
                                        sorting: {
                                            sortModel: [
                                                {
                                                    field: "created_at",
                                                    sort: "desc"
                                                }
                                            ]
                                        }
                                    }}
                                    localeText={localeGrid}
                                />
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}

export default LogsRefferals;
