import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import PercentIcon from "@mui/icons-material/Percent";
import {Checkbox, FormControlLabel, InputAdornment, TextField, Typography,} from "@mui/material";
import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {editArray} from "../store/slices/merchantSlice";
import {AccountCircle, DragIndicator} from "@mui/icons-material";

function MerchantSetting({ merchant }) {
  const merchants = useSelector((state) => state.merchant.merchants);
  const dispatch = useDispatch();

  const { register, control, handleSubmit, setValue, getValues, watch } =
    useForm({
      defaultValues: {
        id: null,
        rate: 0,
        commission: 0,
        min_limit: 0,
        max_limit: 0,
        active: false, // значение по умолчанию для переключателя
      },
    });

  const watchForm = watch(); // you can supply default value as second argument

  React.useEffect(() => {
    // dispatch(editArray(watchLimit))
  }, []);

  React.useEffect(() => {
    dispatch(editArray(watchForm));
  }, [watchForm]);

  React.useEffect(() => {
    setValue("id", merchant.id);
    setValue("rate", merchant.rate);
    setValue("commission", merchant.commission);
    setValue("min_limit", merchant.min_limit);
    setValue("max_limit", merchant.max_limit);

    if (merchant.active == 1) {
      setValue("active", true);
    } else {
      setValue("active", false);
    }
  }, []);

  function fullRate() {
    let rate = getValues("rate");

    let commissionPercent = Number(watchForm.commission) / 100;
    let rateCommission = rate + rate * commissionPercent;
    return rateCommission.toFixed(2);
  }

  return (
          <div>
              <Typography variant="subtitle1" className="mb-3">
                  <div className="d-flex align-items-center">
                          <b>{merchant.priority}. {merchant.type}</b>
                          <div className={"mx-3"}>
                              <FormControlLabel
                                  control={
                                      <Controller
                                          name="active"
                                          control={control}
                                          render={({field}) => (
                                              <Checkbox {...field} checked={field.value}/>
                                          )}
                                      />
                                  }
                                  label="Включить систему"
                              />
                          </div>
                  </div>
              </Typography>
              <div className="w-100 d-flex align-items-center">
                  <div>
                      <DragIndicator color={"disabled"}  fontSize={'large'}/>
                  </div>
                  <div className={'w-100'}>
                      <div className="row">
                          <div className="col-6  d-flex w-100 ">
                              <div className={"w-100"} style={{paddingRight: "5px"}}>
                                  <Controller
                                      name="commission"
                                      control={control}
                                      render={({field}) => (
                                          <TextField
                                              size="small"
                                              sx={{width: "100%"}}
                                              {...field}
                                              type="number"
                                              InputProps={{
                                                  endAdornment: (
                                                      <InputAdornment position="end">
                                                          <PercentIcon/>
                                                      </InputAdornment>
                                                  ),
                                              }}
                                              label={`Комиссия. Итоговый курс - ${fullRate()} ₽`}
                                          />
                                      )}
                                  />
                              </div>
                              <div className={"w-100"} style={{paddingLeft: "5px"}}>

                                  <Controller
                                      name="rate"
                                      control={control}
                                      render={({field}) => (
                                          <TextField
                                              size="small"
                                              sx={{width: "100%"}}
                                              {...field}
                                              type="number"
                                              disabled={true}
                                              InputProps={{
                                                  endAdornment: (
                                                      <InputAdornment position="end">
                                                          <CurrencyRubleIcon/>
                                                      </InputAdornment>
                                                  ),
                                              }}
                                              label="Курс обмена. 1 USDT = "
                                          />
                                      )}
                                  />


                              </div>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-6 mt-3 d-flex w-100 ">
                              <div className={"w-100"} style={{paddingRight: "5px"}}>
                                  <Controller
                                      name="min_limit"
                                      control={control}
                                      render={({field}) => (
                                          <TextField
                                              size="small"
                                              sx={{width: "100%"}}
                                              {...field}
                                              type="number"
                                              InputProps={{
                                                  endAdornment: (
                                                      <InputAdornment position="end">
                                                          <CurrencyRubleIcon/>
                                                      </InputAdornment>
                                                  ),
                                              }}
                                              label={`Минимальный лимит`}
                                          />
                                      )}
                                  />
                              </div>
                              <div className={"w-100"} style={{paddingLeft: "5px"}}>
                                  <Controller
                                      name="max_limit"
                                      control={control}
                                      render={({field}) => (
                                          <TextField
                                              size="small"
                                              sx={{width: "100%"}}
                                              {...field}
                                              type="number"
                                              InputProps={{
                                                  endAdornment: (
                                                      <InputAdornment position="end">
                                                          <CurrencyRubleIcon/>
                                                      </InputAdornment>
                                                  ),
                                              }}
                                              label={`Максимальный лимит`}
                                          />
                                      )}
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div>


          </div>
  );
}

export default MerchantSetting;
